import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PackageView from '../views/PackageView.vue'
import OrderPackages from '../views/OrderPackages.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/package/:uuid',
    name: 'PackageView',
    component: PackageView
  },
  {
    path: '/order/:order_id/:hash',
    name: 'OrderPackages',
    component: OrderPackages
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
