<template>
  <div id="loading">
    <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style>
#loading {
  position: fixed;
  z-index: 1000;
  left:0;
  background-color: white;
  height: 100%;
  width: 100%;
  text-align: center;
}
</style>