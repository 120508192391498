<template>
  <div class="home">
    <HelloWorld msg="Welkom"/>
    <p>Scan een pakket</p>
    <QR></QR>
  
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import QR from '@/components/QR.vue'

export default {
  name: 'Home',
  data() {
    return {
      isLoading: true
    }
  },
  components: {
    HelloWorld,
    QR
  }
}
</script>
