<template>
    <Loading v-if="isLoading"/>
    <div v-if="Object.keys(pkg).length !== 0">
        <p><strong>Doos: {{ pkg.id }}</strong></p>
        <Package :pkg="pkg"/>
    </div>
    <div v-if="error">
        <p>Scan eerst een pakket</p>
    </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Package from '@/components/Package.vue'
export default {
    name: 'PackageView',
    data() {
        return {
            pkg: {},
            productlines: [],
            isLoading: true,
            error: false
        }
    },
    async mounted() {
        this.$store.commit('setPackageUUID', this.$route.params.uuid);
        await this.axios.get(`/packaging/packages/${this.$route.params.uuid}`)
        .then(e => {
            // if e.data is a string, it means the package is not found
            if(typeof e.data === 'string'){
                this.error = true;
                this.isLoading = false;
                return;
            }

            this.pkg = e.data;
            this.productlines = e.data.productlines;
            this.$store.commit('setOrderHash', this.pkg.hash);
            this.isLoading = false;
            this.error = false;
        })
        .catch(e => {
            console.log(e.response.data);
            this.isLoading = false;
            this.error=true;
        })
    },
    components: {
        Loading,
        Package
    }
}
</script>

<style>

</style>