<template>
    <Loading v-if="isLoading"/>
    <div v-if="pkgs.length !=0" id="accordion" class="container">
        <div v-for="pkg in pkgs" :key="pkg.id">
            <div class="card">
                <div class="card-header" :id="'heading'+pkg.id">
                <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" :data-target="'#collapse' + pkg.id" aria-expanded="false" :aria-controls="'collapse'+pkg.id">
                    Pakket: {{pkg.sequence}}
                    </button>
                </h5>
                </div>

                <div :id="'collapse'+pkg.id" class="collapse" aria-labelledby="'heading'+pkg.id" data-parent="#accordion">
                    <div class="card-body">
                        <Package :pkg="pkg"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="error">
        <p>Scan eerst een pakket</p>
    </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Package from '@/components/Package.vue'
export default {
    name: 'OrderPackages',
    data() {
        return {
            pkgs: [],
            isLoading: true,
            error: false
        }
    },
    async mounted() {
        await this.axios.get(`/packaging/packages/order/${this.$route.params.order_id}?hash=${this.$route.params.hash}&lowres=true`)
            .then(e => {
                this.pkgs = e.data
                this.$store.commit('setOrderId', this.$route.params.order_id);
                this.$store.commit('setOrderHash', this.$route.params.hash);
                this.isLoading = false
                this.error = false;
            })
            .catch(e => {
                console.log(e)
                this.isLoading = false;
                this.error = true;
            })   
  },
  components: {
      Loading,
      Package
  }
}
</script>

<style>

</style>