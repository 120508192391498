<template>
    <div id="nav">
        <router-link to="/">Scan</router-link> |
        <router-link :to="'/package/' + $store.state.uuid ">Pakket <span v-if="$store.state.packageId != 0">{{$store.state.packageId}}</span></router-link> 
        <!-- <router-link :to="'/order/' + $store.state.orderId + '/' + $store.state.orderHash">Order <span v-if="$store.state.orderId != 0">{{$store.state.orderId}}</span></router-link> -->
    </div>
</template>
<script>
export default {
    name: 'NavBar'
}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>