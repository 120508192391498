import axios from 'axios'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import QrReader from 'vue3-qr-reader';

library.add(fas)

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(QrReader)
    .component('fa', FontAwesomeIcon)
    .mount('#app')