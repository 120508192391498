import { createStore } from 'vuex'

export default createStore({
  state: {
    packageId: 0,
    uuid: 0,
  },
  mutations: {
    setPackageId(state, id) {
      state.packageId = id;
    },
    setPackageUUID(state, uuid) {
      state.uuid = uuid;
    },
    setOrderHash(state, hash) {
      state.orderHash = hash;
    }
  },
  actions: {
  },
  modules: {
  }
})
