<template>
    <div class="container is-fluid">
      <div class="columns">
        <div class="column">
          <qr-stream :camera="camera" @init="onInit" @decode="onDecode"/>
          {{ error }}
        </div>
  
      </div>
    </div>
  </template>
  <script>
  import { QrStream } from 'vue3-qr-reader';

  export default{
      components: {
          QrStream
      },
      data(){
          return{
              camera: 'auto',
              result: null,
              error: ''
          }
      },
      methods: {
        onDecode(result){
          this.result = result;
          let uuid = result.split('package/')[1];
          this.$router.push({name: 'PackageView', params: {uuid: uuid}});
        },
        onInit (promise){
          promise.then(() => {
            console.log('Successfully initilized! Ready for scanning now!')
          })
          .catch(error => {
            if (error.name === 'NotAllowedError') {
              this.error = 'Hey! I need access to your camera';
            } else if (error.name === 'NotFoundError') {
              this.error = 'Do you even have a camera on your device?';
            } else if (error.name === 'NotSupportedError') {
              this.error = 'Seems like this page is served in non-secure context (HTTPS, localhost or file://)';
            } else if (error.name === 'NotReadableError') {
              this.error = 'Couldn\'t access your camera. Is it already in use?';
            } else if (error.name === 'OverconstrainedError') {
              this.error = 'Constraints don\'t match any installed camera. Did you asked for the front camera although there is none?';
            } else {
              this.error = 'UNKNOWN ERROR: ';
            }
          })
        },
        }
      ,
  }
  </script>
